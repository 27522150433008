var GalantaLogin = {
    init : function() {
        
        jQuery('#lnkRecoverPassword').unbind('click');
        jQuery('#lnkRecoverPassword').bind('click', function(e) {
            e.preventDefault();

            GalantaLogin.moveToRecover();
        });

        jQuery('#lnkRecoverPasswordMobile').unbind('click');
        jQuery('#lnkRecoverPasswordMobile').bind('click', function(e) {
            e.preventDefault();

            GalantaLogin.moveToRecoverMobile();
        });

        jQuery('.login-backlogin').unbind('click');
        jQuery('.login-backlogin').bind('click', function(e) {
            e.preventDefault();

            if (jQuery(window).width() < 768) {
                GalantaLogin.moveToLoginMobile();                    
            } else {
                GalantaLogin.moveToLogin();
            }            
        });        
    },
    moveToRecover: function() {
        var columnLeftWidth = jQuery( ".content-main" ).outerWidth();
        var columnRightWidth = jQuery( ".column-right" ).outerWidth();
        var windowWidth = jQuery( window ).width();
        var moveLeft = windowWidth - columnLeftWidth;
        var moveRight = windowWidth - columnRightWidth;
        var animationTime = 1000;

        jQuery( ".content-main" ).animate({
            left: "+=" + moveLeft,
            }, animationTime, function() {
            // Animation complete.
        });

        jQuery( ".column-right" ).animate({
            right: "+=" + moveRight,
            }, animationTime, function() {
            // Animation complete.
        }); 
        jQuery( ".login-container" ).animate({
            opacity: 0,
            }, animationTime / 2, function() {
            jQuery(".login-container").hide();
            jQuery(".recoverpassword-container").show();
        });  
        jQuery( ".recoverpassword-container" ).animate({
            opacity: 1,
            }, animationTime / 2, function() {
            // Animation complete.
        }); 
    },
    moveToLogin : function() {
        var columnLeftWidth = jQuery( ".content-main" ).outerWidth();
        var columnRightWidth = jQuery( ".column-right" ).outerWidth();
        var windowWidth = jQuery( window ).width();
        var moveLeft = windowWidth - columnLeftWidth;
        var moveRight = windowWidth - columnRightWidth;
        var animationTime = 1000;

        jQuery( ".content-main" ).animate({
            left: "-=" + moveLeft,
            }, animationTime, function() {
            // Animation complete.
        });    
        jQuery( ".column-right" ).animate({
            right: "-=" + moveRight,
            }, animationTime, function() {
            // Animation complete.
        }); 
        jQuery( ".recoverpassword-container" ).animate({
            opacity: 0,
            }, animationTime / 2, function() {
            jQuery(".login-container").show();
            jQuery(".recoverpassword-container").hide();
        });  
        jQuery( ".login-container" ).animate({
            opacity: 1,
            }, animationTime / 2, function() {
            // Animation complete.
        });  
    },
    moveToRecoverMobile: function() {
        var columnRightHeight = jQuery( ".column-right" ).outerHeight();
        var animationTime = 1000;

        jQuery( ".column-right" ).animate({
            bottom: "-=" + columnRightHeight,
            }, animationTime, function() {
                jQuery(".login-container").hide();
                jQuery(".recoverpassword-container").show();

                jQuery( ".column-right" ).animate({
                    bottom: "+=" + columnRightHeight,
                }, animationTime, function() {});
        });
    },
    moveToLoginMobile: function() {
        var columnRightHeight = jQuery( ".column-right" ).outerHeight() + 125;
        var animationTime = 1000;

        jQuery( ".column-right" ).animate({
            bottom: "-=" + columnRightHeight,
            }, animationTime, function() {
                jQuery(".login-container").show();
                jQuery(".recoverpassword-container").hide();

                jQuery( ".column-right" ).animate({
                    bottom: "+=" + columnRightHeight,
                }, animationTime, function() {});
        });
    }
}
jQuery(function() {
    GalantaLogin.init();    
});